import { StrapiTextualProps } from '@/features/strapi/components/textual';
import { StrapiPartnerReviewCardProps } from '@/features/strapi/components/partner-review-card';
import { StrapiPartnerBenefitCardProps } from '@/features/strapi/components/partner-benefit-card';

// @ts-ignore
import growth1 from '../assets/partners/partneresicon1.png';
// @ts-ignore
import growth2 from '../assets/partners/partneresicon2.png';
// @ts-ignore
import growth3 from '../assets/partners/partneresicon3.png';
// @ts-ignore
import growth4 from '../assets/partners/partneresicon4.png';
// @ts-ignore
import partnerImage from '../assets/partners/carolinImage.png';
// @ts-ignore
import verbalImage from '../assets/partners/verbalvisual.png';
// @ts-ignore
import streamImage from '../assets/partners/streamcommerce.png';
// @ts-ignore
import softLimitImage from '../assets/partners/softlimit.png';
// @ts-ignore
import stratImage from '../assets/partners/start88.png';
// @ts-ignore
import doCommImage from '../assets/partners/docommerce.png';
// @ts-ignore
import akantro from '../assets/partners/akantro.png';
// @ts-ignore
import coldsmoke from '../assets/partners/coldsmoke.png';
// @ts-ignore
import vervaunt from '../assets/partners/vervaunt.png';

export const PartnersMainBannerContent = {
  title: {
    size: `4.8rem`,
    weight: `bold`,
    text: `Be the first to introduce Visually,
Secure Clients for Life.`,
    lineHeight: `1.3`,
    mobileMultiplier: 0.6,
    mobileText: `Be the first to introduce
Visually, Secure Clients
for Life.`,
    smMobileMultiplier: 0.58,
  } as StrapiTextualProps,
  subTitle: {
    size: `2.4rem`,
    weight: `normal`,
    lineHeight: `1.8`,
    text: `Introduce your clients to next generation tech: intuitive, agile, and smart
platform to double their sales with no effort. While we make sure you scale
your agency through lifetime glory and rev-share. `,
  } as StrapiTextualProps,
  cta: {
    size: `2.2rem!important`,
    weight: `normal`,
    text: `Become a partner`,
    className: `btn primary-btn small`,
  } as StrapiTextualProps,
  review: {
    image: partnerImage,
    logo: verbalImage,
    name: `Caroline Dau`,
    job: `Senior Director Program
Management at`,
    text: `"Visually's platform is phenomenal in the way it has
allowed us to genuinely grow our client's businesses
through building smarter shopping experiences
with continuous testing and optimization. The ease
of use and the Visually intuitive editor cut us
significant go-live time"`,
  } as StrapiPartnerReviewCardProps,
};

export const PartnersBenefitsContent = {
  title: {
    size: `4.8rem`,
    weight: `bold`,
    text: `Making Moves, Sharing Wins.`,
    mobileMultiplier: 0.8,
    lineHeight: `1.3`,
  } as StrapiTextualProps,
  subTitle: {
    size: `2.4rem`,
    lineHeight: `1.8`,
    text: `Your clients deserve top-notch tech, support, and intuitive solutions to boost their growth.
Position your agency as the guiding force behind your clients' growth by introducing them to
Visually.io, and bask in the glory of your shared success.`,
  } as StrapiTextualProps,
  benefits: [
    {
      title: `Best tech, literally.`,
      image: growth1,
      text: `Be part of the on-site marketing revolution.
Introduce your clients to a tech that really
works- Intuitive, AI, and date-fueled, that lets
them run faster and smarter.`,
    },
    {
      title: `Premium *human* Support.`,
      image: growth2,
      text: `See us as an extension of your team,
we will audit, escort, and support your
clients as if they are the only customers
we have.`,
    },
    {
      title: `Revenue share for life.`,
      image: growth3,
      text: `Sharing is vibing. You share the word, we
share the revenue - for a lifetime.`,
    },
    {
      title: `Growing, together.`,
      image: growth4,
      text: `As a partner, your growth is top of mind.
We’ll join efforts on marketing, case studies,
and events to put you on the spotlight`,
    },
  ] as StrapiPartnerBenefitCardProps[],
  logos: [
    verbalImage,
    streamImage,
    stratImage,
    doCommImage,
    softLimitImage,
    akantro,
    coldsmoke,
    vervaunt,
  ] as string[],
};

export const PartnersJoinContent = {
  title: {
    size: `4.8rem`,
    weight: `bold`,
    text: `Become a Visually Partner`,
    mobileText: `Become a Visually Partner`,
    mobileMultiplier: 0.6,
    smMobileMultiplier: 0.55,
  } as StrapiTextualProps,
  cta: {
    text: `Join Now`,
  },
};
